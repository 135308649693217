import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'

const IndexPage = ({ data }) => (
  <Layout>
    <>
      <h1 className="clip">Cheska Brown</h1>
      <div className="pa3 pt0-l w-100">
        {data.allDatoCmsWork.edges.map(({ node: work }, index) => (
          <figure
            key={work.id}
            className="pb3-l ma0 mt3 relative"
            style={{ minHeight: '200px' }}
          >
            <Link to={`/work/${work.slug}`}>
              <figcaption className="absolute top-0 z-1">
                <h2 className="lh-title black-80 fw4 ma0 pa3">{work.title}</h2>
              </figcaption>

              {!!(work.gallery[0] && work.gallery[0].fluid) && (
                <Img
                  fluid={work.gallery[0].fluid}
                  alt={work.gallery[0].alt}
                  critical={index <= 4}
                  loading={index <= 4 ? 'eager' : 'lazy'}
                  fadeIn={false}
                />
              )}
            </Link>
          </figure>
        ))}
      </div>
    </>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allDatoCmsWork(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          gallery {
            alt
            fluid(maxWidth: 2000, imgixParams: { q: 90, chromasub: 444, minW: 1000 }) {
              aspectRatio
              ...GatsbyDatoCmsSizes_noBase64
            }
          }
        }
      }
    }
  }
`
